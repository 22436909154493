<template>
    <e-iframe-page :loading="loading" ref="iframePage" :title="'Website Settings|Paramètres du site'|tr" body-class="bg-white text-left" body-width="700">

        <div class="text-center">
            <b-img center width="200" :src="cmsWebsite.design.logo.src"/>
            <h3 class="title mt-2" v-tr>
                {{cmsWebsite.name}} Settings|Paramètres de {{cmsWebsite.name|tr}}
            </h3>
        </div>

        <b-tabs class="tabs-pills mt-3" justified>
            <b-tab :title="'General|Général'|tr" class="py-3 px-1">
                <b-form-group :label="'Site Name|Nom du site'|tr">
                    <e-input type="name" v-model="cmsWebsite.name"/>
                    <div class="form-help mt-2" v-tr>
                        Name is for internal use only|Le nom du site est pour usage interne seulement
                    </div>
                </b-form-group>

                <hr>

                <b-form-group :label="'Site Title|Titre du site'|tr">
                    <e-translation-input v-model="cmsWebsite.meta.title"/>
                    <div class="form-help mt-2" v-tr>
                        Your site title appears in your browser tab, along with your favicon|
                        Le titre de votre site apparaît dans l'onglet du navigateur, à côté du favicon
                    </div>
                </b-form-group>

                <hr>

                <b-form-group :label="'Favicon'|tr">
                    <input-image v-model="cmsWebsite.design.favicon.src" category="favicon"/>
                    <div class="form-help mt-2" v-tr>
                        Square image that appears in the browser tab next to the title<br>Recommended size: 32x32px|
                        Image carrée qui apparaît dans l'onglet du navigateur à côté du titre<br>Taille recommandée : 32x32px
                    </div>
                </b-form-group>
                <b-form-group :label="'Logo'|tr">
                    <input-image v-model="cmsWebsite.design.logo.src" size="lg" category="logo"/>
                    <div class="form-help mt-2" v-tr>
                        Recommended size: 250x150px|Taille recommandée : 250x150px
                    </div>
                </b-form-group>

                <hr>

                <b-form-group :label="'Domain Name|Nom de domaine'|tr">
                    <b-input-group>
                        <b-input-group-text>https://</b-input-group-text>
                        <e-input type="text" v-model="cmsWebsite.domain" :readonly="!canAdminEyeInWireless()"/>
                    </b-input-group>
                    <div class="form-help mt-2" v-tr>
                        All your page URLs starts with the domain name|
                        Toutes les URLs de vos pages commencent par le nom de domaine
                    </div>
                </b-form-group>

                <b-form-group :label="'Aliases (Alt Domains)|Alias (domaines alternatifs)'|tr">
                    <b-list-group>
                        <b-list-group-item v-for="(alias, index) in cmsWebsite.aliases" class="d-flex align-items-center interactive p-1" :key="`script-${index}`" :title="alias.comment">
                            <b-input-group>
                                <b-select v-model="alias.env" style="max-width: 90px;">
                                    <option value="LIVE">PROD</option>
                                    <option value="DEMO">DEMO</option>
                                    <option value="DEV">DEV</option>
                                </b-select>
                                <b-input-group-text>https://</b-input-group-text>
                                <e-input type="text" v-model="alias.domain" :readonly="!canAdminEyeInWireless()"/>
                            </b-input-group>
                            <e-button-confirm v-if="canAdminEyeInWireless()" @click="removeAlias(index)" variant="light" class="ml-2">
                                <i class="fas fa-trash"/>
                            </e-button-confirm>
                        </b-list-group-item>
                    </b-list-group>
                </b-form-group>

                <div class="text-center mt-3">
                    <b-btn variant="light-primary" @click="newAlias">
                        <span v-tr>Add Alias|Ajouter alias</span>
                        <i class="fas fa-plus ml-1"/>
                    </b-btn>
                </div>
            </b-tab>
            <b-tab :title="'Integrations|Intégrations'|tr" v-if="canAdminEyeInWireless()" class="py-3 px-1">
                <h4 class="mb-4" v-tr>Integrations|Intégrations</h4>

                <b-form-group>
                    <b-checkbox switch v-model="cmsWebsite.integrations.map.enabled">
                        <span v-tr>Map Integration</span>
                    </b-checkbox>
                </b-form-group>

                <b-form-group :label="'Map ID|ID du plan'|tr" v-if="cmsWebsite.integrations.map.enabled">
                    <b-input v-model="cmsWebsite.integrations.map.mapId"/>
                </b-form-group>
            </b-tab>
            <b-tab :title="'SEO'|tr" class="py-3 px-1">
                <h4 class="mb-4" v-tr>Search Engine Optimization (SEO)|Optimisation des moteurs de recherche (SEO)</h4>

                <e-translation-input v-model="cmsWebsite.meta.title" @change="syncSocial()" label="SEO page Title|Titre de la page pour SEO"/>
                <hr>
                <e-translation-input v-model="cmsWebsite.meta.description" @change="syncSocial()" type="textarea" label="SEO page Description|Description de la page pour SEO"/>
                <b-form-group :label="'Search Engine Visibility|Visibility des moteurs de recherche'|tr">
                    <b-select v-model="cmsWebsite.meta.searchVisibility">
                        <option value="visible" v-tr>Visible to search engines|Visible par les moteurs de recherche</option>
                        <option value="hidden" v-tr>Hidden from search engines results|Caché des résultats des moteurs de recherche</option>
                    </b-select>
                </b-form-group>
            </b-tab>
            <b-tab :title="'Soc. Media|Rés. soc.'|tr" class="py-3 px-1">
                <h4 class="mb-4" v-tr>Social Media|Réseaux sociaux</h4>

                <b-form-group :label="'Social Image|Image aperçu'|tr">
                    <input-image v-model="cmsWebsite.meta.socialImage" size="xl"/>
                </b-form-group>

                <e-translation-input v-model="cmsWebsite.meta.socialTitle" label="Social page Title|Titre pour réseaux sociaux" :readonly="cmsWebsite.meta.socialTitle.sameAsSEO"/>
                <b-checkbox v-model="cmsWebsite.meta.socialTitle.sameAsSEO"><span v-tr>Same as SEO page title|Identique au titre de la page SEO</span></b-checkbox>
                <hr>
                <e-translation-input v-model="cmsWebsite.meta.socialDescription" type="textarea" label="Social page Description|Description page pour réseaux sociaux" :readonly="cmsWebsite.meta.socialDescription.sameAsSEO"/>
                <b-checkbox v-model="cmsWebsite.meta.socialDescription.sameAsSEO"><span v-tr>Same as SEO page title|Identique au titre de la page SEO</span></b-checkbox>
            </b-tab>
            <b-tab :title="'Analytics|Analytique'|tr" class="py-3 px-1">
                <h4 class="mb-4" v-tr>Tracking Tools|Outils d'analytique</h4>

                <b-form-group>
                    <template #label>
                        <i class="fab fa-google mr-1"/> Google Analytics / Tags Manager
                    </template>
                    <e-input v-model="cmsWebsite.settings.analytics.googleTags" placeholder="G-1234567890" maxlength="20" style="max-width: 200px"/>
                    <div class="form-help mt-2" v-tr>
                        Sign up for Google Analytics and follow the instructions to get your Tracking ID.|
                        Inscrivez-vous à Google Analytics et suivez les instructions pour obtenir votre identifiant de suivi.
                    </div>
                </b-form-group>

                <hr>

                <b-form-group>
                    <template #label>
                        <i class="fab fa-facebook mr-1"/> Meta Pixel (Facebook Pixel)
                    </template>
                    <e-input v-model="cmsWebsite.settings.analytics.metaPixel" placeholder="123456789" maxlength="20" style="max-width: 200px"/>
                    <div class="form-help mt-2" v-tr>
                        Sign up for Meta Business and follow the instructions to get your Pixel ID.|
                        Inscrivez-vous à Meta Business et suivez les instructions pour obtenir votre identifiant Meta Pixel.
                    </div>
                </b-form-group>

                <hr>

                <h4 v-tr>Custom Codes & Scripts|Code personnalisé et scripts</h4>

                <b-list-group>
                    <b-list-group-item v-for="(script, index) in cmsWebsite.settings.scripts" class="d-flex align-items-center interactive" :key="`script-${index}`"
                            @click="editScript(script)">
                        <span class="name">{{script.name}}</span>
<!--                        <b-btn @click="editScript(script)" variant="light" class="ml-auto" v-tr>Edit|Modifier</b-btn>-->
                        <b-btn @click="removeScript(index)" variant="danger" class="ml-2"><i class="fas fa-trash"/></b-btn>
                    </b-list-group-item>
                </b-list-group>

                <div class="text-center mt-3">
                    <b-btn variant="light-primary" @click="newScript">
                        <span v-tr>Add New Code|Ajouter nouveau code</span>
                        <i class="fas fa-plus ml-1"/>
                    </b-btn>
                </div>
            </b-tab>
            <b-tab :title="'Redirects|Redirections'|tr" class="py-3 px-1">
                <h4 class="mb-4" v-tr>Redirectors|Redirections</h4>

                <b-list-group>
                    <b-list-group-item v-for="(redirect, index) in cmsWebsite.routing.redirects" class="d-flex align-items-center interactive py-1 pl-2 pr-1"
                                       @click="editRedirect(redirect)" :key="`script-${index}`" :title="redirect.comment">
                        <b-row>
                            <b-col><i class="fas fa-link mr-1"/> {{redirect.from}}</b-col>
                            <b-col><i class="fas fa-arrow-right mr-1"/> {{redirect.to}}</b-col>
                        </b-row>
<!--                        <b-btn @click="editRedirect(redirect)" variant="light" class="ml-auto" v-tr>Edit|Modifier</b-btn>-->
                        <b-btn @click="removeRedirect(index)" variant="danger" class="ml-2"><i class="fas fa-trash"/></b-btn>
                    </b-list-group-item>
                </b-list-group>

                <div class="text-center mt-3">
                    <b-btn variant="light-primary" @click="newRedirect">
                        <span v-tr>Add Redirect|Ajouter redirection</span>
                        <i class="fas fa-plus ml-1"/>
                    </b-btn>
                </div>
            </b-tab>
        </b-tabs>

        <template #footer>
            <b-btn variant="primary" @click="save()">
                <span v-tr>Save|Enregistrer</span>
            </b-btn>
        </template>

        <b-modal v-if="scriptSelected" size="lg" v-model="scriptModalVisible" :title="'Add/Edit Code|Ajouter/Modifier du code'|tr">
            <b-form-group :label="'Custom Code Name|Nom du code personnalisé'|tr">
                <e-input type="name" v-model="scriptSelected.name" :placeholder="'Name your custom code|Donnez un nom à votre code personnalisé'|tr"/>
            </b-form-group>
            <b-form-group :label="'Custom Code|Code personnalisé'|tr">
                <b-textarea v-model="scriptSelected.code" style="min-height: 200px;" :placeholder="'Add you custom <meta>, <script>, or <noscript> code|Ajoutez votre code <meta>, <script>, ou <noscript>'|tr"/>
            </b-form-group>
            <b-form-group :label="'Custom Code|Code personnalisé'|tr">
                <b-select v-model="scriptSelected.appendTo">
                    <option class="f-700" value="head">&lt;head&gt; {{tr('(Recommended)|(Recommandé)')}}</option>
                    <option value="body">{{tr('End of|À la fin du')}} &lt;body&gt;</option>
                </b-select>
            </b-form-group>
            <div class="form-help mt-2" v-tr>
                This code will be added to every page.|
                Ce code sera ajouté sur toutes les pages
            </div>

            <template #modal-footer="{hide}">
                <b-btn variant="light" @click="hide"><span v-tr>Cancel|Annuler</span></b-btn>
                <b-btn variant="primary" v-if="scriptMode === 'add'" @click="addScript"><span v-tr>Add|Ajouter</span></b-btn>
                <b-btn variant="primary" v-else @click="saveScript"><span v-tr>Apply|Appliquer</span></b-btn>
            </template>
        </b-modal>

        <b-modal v-if="redirectSelected" size="lg" v-model="redirectModalVisible" :title="'Add/Edit Redirect|Ajouter/Modifier une redirection'|tr">
            <b-form-group :label="'From|De'|tr">
                <e-input v-model="redirectSelected.from" :placeholder="tr('Ex: /old-page-url|Ex: /ancienne-url')"/>
            </b-form-group>
            <div class="form-help" v-tr>Must start with /|Doit commencer par /</div>
            <b-form-group>
                <b-checkbox v-model="redirectSelected.exactMatch">
                    <span v-tr>Redirect only if matches exactly|Rediriger seulement en cas de correspondance exacte</span>
                </b-checkbox>
            </b-form-group>
            <b-form-group :label="'Redirect to|Rediriger vers'|tr">
                <e-input v-model="redirectSelected.to" :placeholder="tr('Ex: /redirect-to-page|Ex: /url-a-rediriger')" maxlength="1000"/>
            </b-form-group>
            <b-form-group :label="'Comment|Commentaire'|tr">
                <b-textarea v-model="redirectSelected.comment" style="min-height: 120px;" :placeholder="'Add a comment|Ajouter un commentaire'|tr"/>
            </b-form-group>

            <template #modal-footer="{hide}">
                <b-btn variant="light" @click="hide"><span v-tr>Cancel|Annuler</span></b-btn>
                <b-btn variant="primary" v-if="redirectMode === 'add'" @click="addRedirect"><span v-tr>Add|Ajouter</span></b-btn>
                <b-btn variant="primary" v-else @click="saveRedirect"><span v-tr>Apply|Appliquer</span></b-btn>
            </template>
        </b-modal>

        <b-modal v-if="aliasSelected" size="lg" v-model="aliasModalVisible" :title="'Add/Edit Redirect|Ajouter/Modifier une redirection'|tr">
            <b-form-group :label="'From|De'|tr">
                <e-input v-model="aliasSelected.from" :placeholder="tr('Ex: /old-page-url|Ex: /ancienne-url')"/>
            </b-form-group>
            <div class="form-help" v-tr>Must start with /|Doit commencer par /</div>
            <b-form-group>
                <b-checkbox v-model="aliasSelected.exactMatch">
                    <span v-tr>Redirect only if matches exactly|Rediriger seulement en cas de correspondance exacte</span>
                </b-checkbox>
            </b-form-group>
            <b-form-group :label="'Redirect to|Rediriger vers'|tr">
                <e-input v-model="aliasSelected.to" :placeholder="tr('Ex: /redirect-to-page|Ex: /url-a-rediriger')" maxlength="1000"/>
            </b-form-group>
            <b-form-group :label="'Comment|Commentaire'|tr">
                <b-textarea v-model="aliasSelected.comment" style="min-height: 120px;" :placeholder="'Add a comment|Ajouter un commentaire'|tr"/>
            </b-form-group>

            <template #modal-footer="{hide}">
                <b-btn variant="light" @click="hide"><span v-tr>Cancel|Annuler</span></b-btn>
                <b-btn variant="primary" v-if="redirectMode === 'add'" @click="addRedirect"><span v-tr>Add|Ajouter</span></b-btn>
                <b-btn variant="primary" v-else @click="saveRedirect"><span v-tr>Apply|Appliquer</span></b-btn>
            </template>
        </b-modal>
    </e-iframe-page>
</template>

<script>
import InputImage from "@/components/editor/properties/inputs/input-image.vue";
import TabHeader from "@/layout/tab-header.vue";
import EButtonConfirm from "../../vue-components/components/e-button-confirm.vue";
import EEmptyPlaceholder from "../../vue-components/components/e-empty-placeholder.vue";
import EInput from "../../vue-components/components/e-input.vue";
import NetworkV2 from "../../vue-components/helpers/NetworkV2.js";
import ETranslationInput from "../../vue-components/components/e-translation-input.vue";
import EIframePage from "../../vue-components/components/e-iframe-page.vue";

// iframe-website-settings #hot-reload-debug
export default {
    name: `iframe-website-settings`,
    components: {EButtonConfirm, InputImage, EInput, EEmptyPlaceholder, ETranslationInput, TabHeader, EIframePage},
    data() {
        return {
            loading: false,
            scriptSelected: null,
            scriptMode: `add`,
            scriptModalVisible: false,
            redirectSelected: null,
            redirectMode: `add`,
            redirectModalVisible: false
        }
    },
    computed: {
        dealerIds() {
            return this.$store.state.dealers.map(dealer => {
                return dealer.dealerid;
            });
        }
    },
    mounted() {
        if (!this.cmsWebsite.meta) {
            this.cmsWebsite.meta = {
                title: {},
                description: {},
                keywords: {},
                searchVisibility: `visible`,
                socialImage: null,
                socialTitle: {
                    sameAsSEO: true
                },
                socialDescription: {
                    sameAsSEO: true
                }
            };
        }
    },
    methods: {
        /*
        SCRIPTS
         */
        newScript() {
            this.scriptSelected = {
                name: ``,
                code: ``,
                appendTo: `head`
            };
            this.scriptMode = `add`;
            this.scriptModalVisible = true;
        },
        addScript() {
            this.cmsWebsite.settings.scripts.push(this.scriptSelected);
            this.scriptModalVisible = false;
        },
        editScript(script) {
            this.scriptSelected = script;
            this.scriptMode = `edit`;
            this.scriptModalVisible = true;
        },
        saveScript() {
            this.scriptModalVisible = false;
        },
        removeScript(index) {
            this.cmsWebsite.settings.scripts.splice(index, 1);
        },
        /*
        REDIRECTS
         */
        newRedirect() {
            this.redirectSelected = {
                from: ``,
                to: ``,
                comment: ``,
                exactMatch: false,
                dateCreation: new Date()
            };
            this.redirectMode = `add`;
            this.redirectModalVisible = true;
        },
        addRedirect() {
            this.cmsWebsite.routing.redirects.push(this.redirectSelected);
            this.redirectModalVisible = false;
        },
        editRedirect(redirect) {
            this.redirectSelected = redirect;
            this.redirectMode = `edit`;
            this.redirectModalVisible = true;
        },
        saveRedirect() {
            this.redirectModalVisible = false;
        },
        removeRedirect(index) {
            this.cmsWebsite.routing.redirects.splice(index, 1);
        },
        /*
        ALIASES
         */
        newAlias() {
            this.cmsWebsite.aliases.push({
                domain: ``,
                env: `DEV`
            });
        },
        removeAlias(index) {
            this.cmsWebsite.aliases.splice(index, 1);
        },
        save() {
            this.showLoading();

            this.cmsWebsite.settings.init = true;

            NetworkV2.post(`/api/websites/edit`, this.cmsWebsite)
                .then(resp => {
                    this.showSavedMessage();
                    this.$refs.iframePage.saved(resp.data);
                });
        },
        syncSocial() {
            if (this.cmsWebsite.meta.socialTitle.sameAsSEO) {
                for (let lang in this.cmsWebsite.meta.title) {
                    this.cmsWebsite.meta.socialTitle[lang] = this.cmsWebsite.meta.title[lang];
                }
            }
            if (this.cmsWebsite.meta.socialDescription.sameAsSEO) {
                for (let lang in this.cmsWebsite.meta.description) {
                    this.cmsWebsite.meta.socialDescription[lang] = this.cmsWebsite.meta.description[lang];
                }
            }
        }
    }
}
</script>

<style scoped>

</style>
